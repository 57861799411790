<template>
  <router-view />
</template>
<style lang="less">
* {
  margin: 0;
  padding: 0;
}
</style>
<!-- <script>
export default {
  data() {
    return {
      ratio: 1, // 初始化 ratio
    };
  },
  methods: {
    keepRatio() {
      let ratio = 0; // 定义一个缩放比例
      const screen = window.screen; // 获取窗口对象
      const ua = navigator.userAgent.toLowerCase();

      if (window.devicePixelRatio !== undefined) {
        ratio = window.devicePixelRatio; // 像素大小的比例
      } else if (ua.indexOf('msie') !== -1) {
        if (screen.deviceXDPI && screen.logicalXDPI) {
          ratio = screen.deviceXDPI / screen.logicalXDPI;
        }
      } else if (window.outerWidth !== undefined && window.innerWidth !== undefined) {
        ratio = window.outerWidth / window.innerWidth; // 外部比例/内部比例：缩放比例
      }

      if (ratio) {
        ratio = Math.round(ratio * 100);
      }

      this.ratio = (ratio / 100).toFixed(2);
      document.body.style.zoom = 1 / this.ratio; // 窗口视图除以缩放比例：即缩放还原
    },
  },
  mounted() {
    this.keepRatio();
    window.addEventListener('resize', this.keepRatio); // 监听窗口缩放
  },
  beforeDestroy() {
    // 确保在组件销毁前移除监听器
    window.removeEventListener('resize', this.keepRatio);
  },
};
</script> -->
